import { lazy, Suspense } from 'react';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { atomOneLight } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { Check } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { useBreakpoint } from 'src/hooks';
const SyntaxHighlighter = lazy(() => import('react-syntax-highlighter'));

export const CodeMarkdown = ({
  node,
  inline,
  className = 'language-python',
  style,
  children,
  ...props
}: CodeProps) => {
  const { isDesktopAndUp } = useBreakpoint();

  const match = /language-(\w+)/.exec(className || '');

  const handleCopyClick = (dataToCopy: string) => {
    navigator.clipboard.writeText(dataToCopy);
    toast(
      <div className="nj-copy-snackbar-content">
        <Check size={24} />
        <span>Code Copied</span>
      </div>,
      {
        className: 'nj-copy-snackbar',
      }
    );
  };

  if (!inline && match) {
    return (
      <div className="nj-markdown-block-wrapper">
        <div className="nj-markdown-copy-block-wrapper">
          <h6>{match[1]}</h6>
          <button onClick={() => handleCopyClick(String(children))}>
            Copy code
          </button>
        </div>
        <hr className="divider" />
        <Suspense fallback={<pre>{String(children).replace(/\n$/, '')}</pre>}>
          <SyntaxHighlighter
            style={atomOneLight}
            language={match[1]}
            PreTag="div"
            // attn: if "showLineNumbers" is true, "wrapLongLines" must be false. Issue: https://github.com/react-syntax-highlighter/react-syntax-highlighter/issues/376
            wrapLongLines={!isDesktopAndUp}
            showLineNumbers={isDesktopAndUp}
            {...props}
          >
            {String(children).replace(/\n$/, '')}
          </SyntaxHighlighter>
        </Suspense>
      </div>
    );
  }

  return (
    <code className={className} {...props}>
      {children}
    </code>
  );
};
