import { ImageCard } from 'src/types/models/ImageCard';
import { ResearchMediaCarousel } from 'src/components/SharedMessage/components/ResearcherCard/components/ResearchMediaCarousel';
import { useContext, useEffect, useMemo } from 'react';
import SessionContext from 'src/contexts/SessionContext';
import { ModalGalleryType } from 'src/types';
import { prepareGeneratedImages } from 'src/utils';
import './ThreadImageGenerationCard.scss';

interface ThreadImageGenerationCardProps {
  imageCard: ImageCard;
}

export const ThreadImageGenerationCard = ({
  imageCard,
}: ThreadImageGenerationCardProps) => {
  const { images } = imageCard;
  const { onChangeModalGalleryData } = useContext(SessionContext);
  const imagesArray = useMemo(() => {
    if (images) {
      return prepareGeneratedImages(images);
    }
    return [];
  }, [images]);

  const handleSetCurrentMedia = (index: number) => {
    onChangeModalGalleryData(
      true,
      ModalGalleryType.GENERATED_IMAGES,
      imagesArray,
      index
    );
  };

  // Gallery reflow is impacting the layout calculation and it's showing wrongly the arrow navigation
  // so this workaround is helping to fix it
  useEffect(() => {
    onChangeModalGalleryData(false, null, [], 0);
  }, []);

  return (
    <div className="nj-thread-research-media nj-thread-image-generation-media-wrapper">
      <h6 className="nj-thread-research-card--summary-title">
        Image generator
      </h6>

      <ResearchMediaCarousel
        videos={[]}
        images={imagesArray}
        setCurrentMedia={handleSetCurrentMedia}
      />
    </div>
  );
};
