import { useMemo } from 'react';
import dayjs from 'dayjs';

interface MessageDateProps {
  timestamp?: string;
}

export const MessageDate = ({ timestamp }: MessageDateProps) => {
  if (!timestamp) {
    return null;
  }

  const date = useMemo(
    () => dayjs(timestamp).format('h:mm A, MMM D'),
    [timestamp]
  );

  return <span className="nj-message--date">Generated on {date}</span>;
};
