import { MessageContent } from '../MessageContent';
import classNames from 'classnames';
import { useCollapse } from 'react-collapsed';
import { ShowMoreLessButton } from 'src/components/ShowMoreLessButton';
import styles from './Summary.module.scss';

const SVG_SIZE = 24;
import { Stack } from '@phosphor-icons/react';
import { ExternalModelReference } from 'src/types';

type ColorPalette = 'plain' | 'blue' | 'light-blue';

interface SummaryProps {
  title: string;
  content: string;
  isCollapsed?: boolean;
  colorPalette?: ColorPalette;
  references?: ExternalModelReference[];
}

export const Summary = ({
  title,
  content,
  isCollapsed = true,
  colorPalette = 'plain',
  references,
}: SummaryProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className={classNames(styles.root, styles[colorPalette])}>
      <h5 className={styles.title}>
        <Stack size={SVG_SIZE} />
        <span>{title}</span>
      </h5>

      {content && !isCollapsed && (
        <MessageContent references={references} content={content} />
      )}

      {content && isCollapsed && (
        <>
          {!isExpanded && (
            <div className={styles.collapsedContentBlock}>
              <MessageContent references={references} content={content} />
              <div className={styles.collapsedContentCoverage} />
            </div>
          )}

          <div {...getCollapseProps()}>
            <MessageContent references={references} content={content} />
          </div>

          <ShowMoreLessButton
            getToggleProps={getToggleProps}
            isExpanded={isExpanded}
          />
        </>
      )}
    </div>
  );
};
