import { useMemo } from 'react';
import ReactMarkdown from 'react-markdown';
import { CodeProps } from 'react-markdown/lib/ast-to-react';
import { ReactMarkdownProps } from 'react-markdown/lib/complex-types';
import breaks from 'remark-breaks';
import gfm from 'remark-gfm';
import { Citation } from 'src/types';
import { CITATION_REGEX, CODE_REGEX } from 'src/constants';
import { LinkMarkdown } from './components/LinkMarkdown';
import { CodeMarkdown } from './components/CodeMarkdown';

interface MarkdownProps {
  children: string;
  references?: Citation[];
}

/**
 * Markdown handles markdown for the following:
 * - Headings (H1, H2, H3), use hash (# Heading 1,  ## Heading 2,  ### Heading 3)
 * - Bold, use double asterisks (**Bold**)
 * - Italics, use single asterisks (*Italics*)
 * - Tables, use pipe (|) and hyphen (-)
 * - Lists, use hyphen (-) or asterisk (*)
 * - Links, use square brackets and parentheses ([Link](https://www.example.com))
 * - Images, use exclamation mark and square brackets (![Alt text](https://www.example.com/image.jpg))
 * - Blockquotes, use greater than sign (>)
 * - Code, use backticks (`) and triple backticks (```)
 * - Strikethrough, use double tilde (~~Strikethrough~~)
 * - Newlines, use double space and newline (  \n)
 * - Horizontal rules, use three hyphens (---)
 * - Task lists, use hyphen (-) and square brackets ([x] or [ ])
 * - Footnotes, use caret and square brackets (^[Footnote])
 * - Superscript, use caret and parentheses (^Superscript)
 * - Subscript, use tilde and parentheses (~Subscript)
 * - Abbreviations, use square brackets and parentheses ([HTML](https://www.example.com) or HTML)
 * - Definition lists, use colon (:) and hyphen (-)
 * - Math, use dollar sign ($) and backticks (`)
 * - Emoji, use colon (:) and hyphen (-)
 * - HTML, use angle brackets (<HTML>)
 * @param children
 * @returns
 */
export const Markdown = ({ children, references }: MarkdownProps) => {
  const updatedChildren = useMemo(() => {
    return children
      .replace(/\n\n(\d+)/g, (_, p1) => `\n\n${p1}`)
      .replace(/\n/gi, '  \n')
      .replace(CITATION_REGEX, (match) => `${match}(#)`)
      .replace(CODE_REGEX, '```$1\n');
  }, [children]);

  return (
    <ReactMarkdown
      unwrapDisallowed
      remarkPlugins={[breaks, gfm]}
      components={{
        a: ({ ...props }: ReactMarkdownProps) => (
          <LinkMarkdown references={references} {...props} />
        ),
        code: ({ ...props }: CodeProps) => <CodeMarkdown {...props} />,
      }}
    >
      {updatedChildren}
    </ReactMarkdown>
  );
};
