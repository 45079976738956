import { forwardRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import { ANIMATION_TIMEOUT } from 'src/constants';

interface OverlayBackgroundProps {
  showOverlay: boolean;
  zIndex?: number;
  className?: string;
  onClick?: () => void;
}

// eslint-disable-next-line react/display-name
export const OverlayBackground = forwardRef<
  HTMLDivElement,
  OverlayBackgroundProps
>(({ showOverlay, zIndex = 0, className, onClick }, ref) => {
  return (
    <CSSTransition
      in={showOverlay}
      timeout={ANIMATION_TIMEOUT}
      classNames="nj-animate-opacity"
      unmountOnExit
    >
      <div
        className={classNames('nj-overlay-background', className)}
        style={{ zIndex }}
        onClick={onClick}
        ref={ref}
      />
    </CSSTransition>
  );
});
