import { ArrowsClockwise } from '@phosphor-icons/react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { sanitize } from 'dompurify';
import { CalendarCardEvent } from 'src/types';
import { SCHEDULE_ACTION_CREATED } from 'src/constants';
import { Markdown } from 'src/components/Markdown';

const SVG_SIZE = 16;

interface ScheduleEventProps {
  event: CalendarCardEvent;
}

export const ScheduleEvent = ({ event }: ScheduleEventProps) => {
  const { end, start, title, action, is_recurring, is_all_day } = event;
  const timeRange = is_all_day
    ? 'All day'
    : `${dayjs(start).format('h:mm A')}-${dayjs(end).format('h:mm A')}`;

  return (
    <div
      className={classNames('nj-thread-schedule-card--event', {
        selected: action === SCHEDULE_ACTION_CREATED,
      })}
    >
      <p className="nj-thread-schedule-card--event-time-range">
        {is_recurring && <ArrowsClockwise size={SVG_SIZE} />}

        <span>{timeRange}</span>
      </p>

      <div className="nj-thread-schedule-card--event-title">
        <Markdown>{sanitize(title)}</Markdown>
      </div>
    </div>
  );
};
