import { MessageContent } from 'src/components/SharedMessage/components/MessageContent';
import { useCollapse } from 'react-collapsed';
import { useMemo } from 'react';
import { sanitize } from 'dompurify';
import { Markdown } from 'src/components/Markdown';
import { ShowMoreLessButton } from 'src/components/ShowMoreLessButton';
import { ListMagnifyingGlass } from '@phosphor-icons/react';
import { SearchWebPageReference } from 'src/types';

type ResearchSummaryProps = {
  title?: JSX.Element | string;
  summary?: string;
  isCollapsed?: boolean;
  hasIcon?: boolean;
  references?: SearchWebPageReference[];
};

const showLessSummaryLabel = 'Show less summary';
const showMoreSummaryLabel = 'Show all summary';

export const ResearchSummary = ({
  title = '',
  summary = '',
  isCollapsed = false,
  hasIcon = false,
  references = [],
}: ResearchSummaryProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const contentHTML = useMemo(
    () => sanitize(summary.toString() || ''),
    [summary]
  );

  if (!summary) {
    return null;
  }

  const SVG_SIZE = 24;

  return (
    <div className="nj-thread-research-card--summary">
      {title && (
        <div className="nj-thread-research-card--summary-header">
          {hasIcon && <ListMagnifyingGlass size={SVG_SIZE} />}
          <h2 className="nj-thread-research-card--summary-title">{title}</h2>
        </div>
      )}

      {!isExpanded && isCollapsed && (
        <div>
          <div className="nj-thread-research-card--collapsed-content-block">
            <Markdown references={references}>{contentHTML}</Markdown>
          </div>
          <ShowMoreLessButton
            getToggleProps={getToggleProps}
            isExpanded={isExpanded}
            showLessLabel={showLessSummaryLabel}
            showMoreLabel={showMoreSummaryLabel}
          />
        </div>
      )}

      {isCollapsed ? (
        <div {...getCollapseProps()}>
          <MessageContent references={references} content={summary} />
          <ShowMoreLessButton
            getToggleProps={getToggleProps}
            isExpanded={isExpanded}
            showLessLabel={showLessSummaryLabel}
            showMoreLabel={showMoreSummaryLabel}
          />
        </div>
      ) : (
        <MessageContent references={references} content={summary} />
      )}
    </div>
  );
};
