/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMemo } from 'react';
import { ShareButton } from 'src/components/ShareButton';
import { MessageDate } from 'src/components/SharedMessage/components/MessageDate';
import { CopyMessageButton } from './components/CopyMessageButton';
import {
  isImageGenerationCard,
  SharedMessage as SharedMessageType,
} from 'src/types';
import { MessageContent } from 'src/components/SharedMessage/components/MessageContent/MessageContent';
import { ResearcherCard } from './components/ResearcherCard';
import { SchedulerCard } from './components/SchedulerCard';
import { CoderCard } from './components/CoderCard';
import { ChatCard } from './components/ChatCard';
import Avatar from 'src/images/metahuman/ninja.png';
import { MessageType } from 'src/types';
import { RelatedQuestions } from 'src/components/RelatedQuestions';
import { CollapsibleTitle } from 'src/components/CollapsibleTitle';
import { ThreadImageGenerationCard } from '../ThreadImageGenerationCard';
import { ImageCard } from 'src/types/models/ImageCard';
import {
  FileCsv,
  FileDoc,
  FilePdf,
  FileText,
  FileTxt,
  FileXls,
  Image,
} from '@phosphor-icons/react';

export const FILE_REGEX =
  /---\[original-filename="([^"]+)" converted-filename="([^"]+)"\]---/;
export const EDIT_FILE_REGEX = /---\[edit-image-url="([^"]+)"\]---/;

interface SharedMessageProps {
  message: SharedMessageType;
  loading?: boolean;
}

const IMG_SIZE = 29;
const SVG_SIZE = 20;

export const SharedMessage = ({ message }: SharedMessageProps) => {
  const { message_type, content = '', payload } = message;

  const displayMessage = useMemo(() => {
    switch (message_type) {
      case MessageType.RESEARCH_TASK_CREATION_CARD:
        return <ResearcherCard message={message} />;

      case MessageType.CHAT_CARD:
        return <ChatCard message={message} />;

      case MessageType.IMAGE_CARD:
        return <ThreadImageGenerationCard imageCard={payload as ImageCard} />;

      // TODO(olha): deprecated card
      case MessageType.CODE_TASK_CREATION_CARD:
        return <CoderCard message={message} />;

      // TODO(olha): deprecated card
      case MessageType.SCHEDULER_TASK_CREATION_CARD:
        return <SchedulerCard message={message} />;

      default:
        return <MessageContent content={content} />;
    }
  }, [message, message_type]);

  const relatedQuestions = useMemo(() => {
    return (
      // TODO(olha): add type guards and remove ts-ignore when BE is ready
      // @ts-ignore
      payload?.research_card?.data?.related_questions ||
      // @ts-ignore
      payload?.data?.related_questions ||
      // @ts-ignore
      payload?.related_questions ||
      undefined
    );
  }, [payload]);

  const query = useMemo(() => {
    return (
      message.original_query ||
      message.clean_query ||
      message.refined_query ||
      `${content.slice(0, 60).trim()}...`
    );
  }, [message, message_type]);

  const isContentWithFile =
    query.length > 0 &&
    !!query.match(FILE_REGEX) &&
    (query.match(FILE_REGEX) || []).length >= 3;

  const isContentWithEditFile =
    query.length > 0 &&
    !!query.match(EDIT_FILE_REGEX) &&
    (query.match(EDIT_FILE_REGEX) || []).length >= 2;

  const originalFilename = useMemo(() => {
    if (isContentWithFile) {
      return query.match(FILE_REGEX) !== null
        ? (query.match(FILE_REGEX) || [])[1].replaceAll('"', '')
        : '';
    }
    if (isContentWithEditFile) {
      return query.match(EDIT_FILE_REGEX) !== null
        ? (query.match(EDIT_FILE_REGEX) || [])[1].split('/').pop()
        : '';
    }
    return '';
  }, [query, isContentWithFile, isContentWithEditFile]);

  const renderExceptionIcon = (type: string) => {
    switch (type) {
      case 'pdf':
        return <FilePdf size={SVG_SIZE} />;
      case 'txt':
        return <FileTxt size={SVG_SIZE} />;
      case 'docx':
        return <FileDoc size={SVG_SIZE} />;
      case 'csv':
        return <FileCsv size={SVG_SIZE} />;
      case 'xls':
        return <FileXls size={SVG_SIZE} />;
      case 'png':
      case 'jpeg':
      case 'jpg':
        return <Image size={SVG_SIZE} />;
      default:
        return <FileText size={SVG_SIZE} />;
    }
  };

  return (
    <div className="nj-message">
      <div className="nj-message--header">
        <img src={Avatar} alt="Avatar" width={IMG_SIZE} height={IMG_SIZE} />
        {isContentWithFile || isContentWithEditFile ? (
          <div className="nj-message--header-query-wrapper">
            <h3>
              <CollapsibleTitle
                query={query
                  ?.replace(FILE_REGEX, '')
                  .replace(EDIT_FILE_REGEX, '')}
              />
            </h3>
            {!!originalFilename && (
              <button
                type="button"
                onClick={() => {
                  //todo
                }}
                className="nj-message-header-with-file-wrapper--file-label"
              >
                <div className="nj-message-header-with-file-wrapper--file-label-text">
                  {renderExceptionIcon(originalFilename.split('.')[1])}
                  <span className="nj-message-header-with-file-wrapper--file-name ellipsis">
                    {originalFilename}
                  </span>
                </div>
              </button>
            )}
          </div>
        ) : (
          <h3>
            <CollapsibleTitle
              query={query
                ?.replace(FILE_REGEX, '')
                .replace(EDIT_FILE_REGEX, '')}
            />
          </h3>
        )}
      </div>
      <div className="nj-message--subheader">
        <MessageDate timestamp={message.timestamp} />
        <div className="nj-message--links">
          <CopyMessageButton item={message} />
          <ShareButton />
        </div>
      </div>
      <div className="nj-message--body">{displayMessage}</div>

      <RelatedQuestions
        data={relatedQuestions}
        defaultExpanded={true}
        title={
          isImageGenerationCard(payload)
            ? 'Related Queries'
            : 'Related Questions'
        }
      />
    </div>
  );
};
