/**
 * Uppercase first letter of the word
 * @param str string
 * @returns string
 */
export function uppercaseFirstLetter(str: string): string {
  if (!str) {
    return '';
  }
  return [...str][0].toUpperCase() + str.slice(1);
}

/**
 * Pauses execution for a given number of milliseconds.
 * @param delay number
 * @returns promise
 */
export function pause(delay: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve();
    }, delay);
  });
}

/**
 * Get random item from an array.
 * @param arr string[]
 * @returns string
 */
export function getRandomText(arr: string[]): string {
  return arr[Math.floor(Math.random() * arr.length)];
}

/**
 * Generates color & returns back circle color classname.
 * @returns string
 */
export function getCircleColor(): string {
  const colors = [
    'green-circle',
    'blue-circle',
    'purple-circle',
    'pink-circle',
    'orange-circle',
    'marine-circle',
  ];
  return getRandomText(colors);
}

/**
 * removeReferencesInBrackets() removes references in brackets: [3][7].
 * @param str string
 * @returns string
 */
export function removeReferencesInBrackets(str: string) {
  if (!str) return '';
  /* eslint-disable no-useless-escape */
  return str.replace(/\[\d\]/g, '').replace(/,{2,}/gi, ',');
}

/**
 * processUserCommands() converts textual emails to links & formats commands.
 * @param text string
 * @returns string
 */
export function processUserCommands(text: string): string {
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;

  const emailProofed = text.replace(emailRegex, (match) => {
    return `<a href="mailto:${match}">${match}</a>`;
  });

  const formattedResult = emailProofed
    .replace(/\/calendar/gi, `<b>/calendar</b>`)
    .replace(/\/research-llm/gi, `<b>/research-llm</b>`)
    .replace(/\/research-code/gi, `<b>/research-code</b>`)
    .replace(/\/research/gi, `<b>/research</b>`)
    .replace(/\n/gi, '<br>');
  return formattedResult;
}

/**
 * Format hours and return the formatted date.
 * Sample format: `10:00 AM`
 * @param timestamp
 * @param locale
 * @param timeZone
 * @returns string
 */
export function formatHours(
  timestamp: number | string,
  locale = 'en-US',
  timeZone?: string
): string {
  return new Date(timestamp).toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: timeZone,
  });
}

/**
 * Removes invalid characters from a string intended to be used as an HTML ID.
 * Retains only alphanumeric characters, dashes, and underscores, making the string safe for use
 * as HTML IDs and CSS selectors. This simplifies interaction with the DOM via JavaScript and CSS.
 *
 * @param id The original identifier string that may contain invalid characters.
 * @returns A sanitized identifier string with only valid characters.
 */
export const sanitizeHTMLId = (id: string) => {
  return id.replace(/[^a-zA-Z0-9\-_]/g, '');
};
