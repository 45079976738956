import { useMemo } from 'react';
import classNames from 'classnames';
import chatGPTLogo from 'src/images/logos/chat-gpt.svg';
import claudeLogo from 'src/images/logos/anthropic-claude-logo.svg';
import googlePalmLogo from 'src/images/logos/google-palm-logo.svg';
import googleGeminiProLogo from 'src/images/logos/google-gemini-pro-logo.svg';
import ninjaLogoIcon from 'src/images/ninja-blue-logo-icon.svg';
import avatarNinja from 'src/images/avatars/AiAvatarNinja.png';
import attention from 'src/images/icons/AttentionDot.svg';
import company from 'src/images/icons/Windows.png';
import defaultAvatar from 'src/images/icons/defaultAvatar.svg';
import waveform from 'src/images/icons/waveform.svg';
import ninjaIcon from 'src/images/logos/ninja-icon.svg';
import openaiIcon from 'src/images/logos/openai-icon.svg';
import gmailLogo from 'src/images/logos/gmail-logo.svg';
import slackLogo from 'src/images/logos/slack-logo.svg';
import calendar from 'src/images/icons/calendar.png';
import contact from 'src/images/icons/contact.png';
import googleWhite from 'src/images/logos/google-white-logo.svg';
import landscape from 'src/images/icons/landscape.svg';
import portrait from 'src/images/icons/portrait.svg';
import square from 'src/images/icons/square.svg';
import metaLogo from 'src/images/logos/meta-logo.svg';
import envelope from 'src/images/icons/envelope.svg';
import listGradient from 'src/images/icons/list-gradient.svg';
import googleCalendarLogo from 'src/images/logos/google-calendar-logo.svg';
import outlookLogo from 'src/images/logos/outlook-logo.svg';
import awsLogo from 'src/images/logos/aws-logo.png';
import cohereLogo from 'src/images/logos/cohere-logo.svg';
import jurassicLogo from 'src/images/logos/jurassic-logo.png';
import mistralLogo from 'src/images/logos/mistral-logo.png';
import bedrockStableLogo from 'src/images/logos/bedrock-stable-logo.svg';
import { getCircleColor } from 'src/utils/index';

type IconMap = {
  [key: string]: string;
};

const iconMap: IconMap = {
  company,
  attention,
  ninjaIcon,
  openaiIcon,
  avatarNinja,
  defaultAvatar,
  waveform,
  gmailLogo,
  slackLogo,
  calendar,
  contact,
  googleWhite,
  landscape,
  portrait,
  square,
  chatGPTLogo,
  claudeLogo,
  metaLogo,
  googlePalmLogo,
  googleGeminiProLogo,
  ninjaLogoIcon,
  envelope,
  googleCalendarLogo,
  outlookLogo,
  listGradient,
  awsLogo,
  cohereLogo,
  jurassicLogo,
  mistralLogo,
  bedrockStableLogo,
};

type IconProps = {
  src?: string;
  type?: string;
  alt?: string;
  size?: number;
  masterColor?: string;
  rest?: { [key: string]: React.ReactNode };
};

/**
 * Icon component displays icon with an image based
 * on the props provided, or a color circle instead.
 */
export const Icon = ({
  src = '',
  type = '',
  size = 32,
  alt = '',
  masterColor = '',
  ...rest
}: IconProps) => {
  const color = useMemo(() => masterColor || getCircleColor(), [masterColor]);
  const avatar = src !== '' ? src : iconMap[type];
  const styles = {
    width: `${size}px`,
    height: `${size}px`,
    lineHeight: `${size}px`,
  };

  const circleClasses = classNames('circle', color);
  return (
    <span className="icon" style={styles}>
      {avatar ? (
        <img
          referrerPolicy="no-referrer"
          src={avatar}
          alt={alt}
          width={size}
          height={size}
          {...rest}
        />
      ) : (
        <span data-testid="circle-icon" className={circleClasses}>
          {alt}
        </span>
      )}
    </span>
  );
};
