import { useCollapse } from 'react-collapsed';
import { CollapsedButton } from 'src/components/CollapsedButton';
import { useEffect } from 'react';
import { MessageContent } from 'src/components/SharedMessage/components/MessageContent';

interface CodeSourceProps {
  title: string;
  content: string;
  icon: JSX.Element;
  isExpanded: boolean;
}

export const CodeSource = ({
  title,
  content,
  icon,
  isExpanded: isExpandedByDefault,
}: CodeSourceProps) => {
  const { getCollapseProps, getToggleProps, isExpanded, setExpanded } =
    useCollapse();

  useEffect(() => {
    setExpanded(isExpandedByDefault);
  }, [isExpandedByDefault]);

  return (
    <li className="nj-thread-research-card--source">
      <div
        className="nj-thread-research-card--source-header"
        {...getToggleProps()}
      >
        <h6 className="nj-thread-research-card--source-title">
          {icon}
          <span>{title}</span>
        </h6>

        <CollapsedButton isExpanded={isExpanded} />
      </div>

      <div {...getCollapseProps()}>
        <div>
          <MessageContent content={content} />
        </div>
      </div>
    </li>
  );
};
