import { Atom } from '@phosphor-icons/react';
import { MessageContent } from 'src/components/SharedMessage/components/MessageContent';
import { SearchWebPageReference } from 'src/types/models/SearchWebPageReference';

type ResearchDeepResearchSectionProps = {
  content?: string;
  references?: SearchWebPageReference[];
};

export const ResearchDeepResearchSection = ({
  content = '',
  references,
}: ResearchDeepResearchSectionProps) => {
  if (!content) {
    return null;
  }

  return (
    <div className="nj-thread-research-card--deep-research">
      <div className="nj-thread-research-card--deep-research-header">
        <h2 className="nj-thread-research-card--deep-research-title">
          <Atom size={24} />
          <span>Deep Research</span>
        </h2>
      </div>

      <MessageContent references={references} content={content} />
    </div>
  );
};
