import { useMemo } from 'react';
import { sanitize } from 'dompurify';
import classNames from 'classnames';
import { Markdown } from 'src/components/Markdown';
import { removeReferencesInBrackets } from 'src/utils';
import { Citation } from 'src/types';

type MessageContentProps = {
  className?: string;
  content: string;
  references?: Citation[];
};

export const MessageContent = ({
  className,
  content,
  references,
}: MessageContentProps) => {
  const contentHTML = useMemo(
    () => sanitize(removeReferencesInBrackets(content.toString())),
    [content]
  );

  return (
    <div className={classNames('nj-thread-message--content', className)}>
      <Markdown references={references}>{contentHTML}</Markdown>
    </div>
  );
};
