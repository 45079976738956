import { ReactNode, useContext, useRef } from 'react';
import { OverlayBackground } from 'src/components/OverlayBackground';
import { CSSTransition } from 'react-transition-group';
import { ANIMATION_TIMEOUT } from 'src/constants';
import { ModalGalleryType } from 'src/types';
import SessionContext from 'src/contexts/SessionContext';

type MobileModalOverlayListProps = {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
};

export const MobileModalOverlayList = ({
  isOpen,
  onClose,
  children,
}: MobileModalOverlayListProps) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { currentModalGalleryType } = useContext(SessionContext);

  const renderMobileListTitle = () => {
    switch (currentModalGalleryType) {
      case ModalGalleryType.IMAGES_VIDEOS:
        return 'Videos & Images results';
      case ModalGalleryType.EXTERNAL_MODELS:
        return 'External model results';
      case ModalGalleryType.REFERENCES:
        return 'References list';
      case ModalGalleryType.GENERATED_IMAGES:
        return 'Image generator results';
      default:
        return <></>;
    }
  };

  return (
    <>
      <OverlayBackground
        ref={overlayRef}
        showOverlay={isOpen}
        zIndex={1150}
        onClick={onClose}
      />

      <CSSTransition
        in={isOpen}
        timeout={ANIMATION_TIMEOUT}
        classNames="nj-animate-vertical-appearance"
        unmountOnExit
      >
        <div
          ref={containerRef}
          className="nj-mobile-action-panel nj-mobile-modal-overlay-list-panel"
        >
          <div className="nj-mobile-modal-overlay-list-panel-header-wrapper">
            <hr
              onClick={onClose}
              className="nj-mobile-action-panel--top-marker"
            />
            <span className="nj-modal-overlay-mobile-list-panel-header">
              {renderMobileListTitle()}
            </span>
          </div>

          {children}
        </div>
      </CSSTransition>
    </>
  );
};
